
import React from 'react';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import QRCode from 'react-qr-code';
import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';

const Deposit = () => {
    const [data, setData] = useState('');
    const [desposit, setDesposit] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);

    const handledashboard = async () => {
        try {
            let response = await _fetch(`${base_url}api/user/depositQrCode`, 'get');
            console.log('Fetched data:', response);
            if (response.success) {
                setData(response.data);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    };

    const handledesposit = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize || 0;
            setpageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip,
            };

            console.log(`Fetching deposits: page=${page}, pageSize=${pageSize}, skip=${skip}`);

            let response = await _fetch(`${base_url}api/user/deposit`, 'post', requestData);
            console.log('response ', response);
            if (response.success) {
                setDesposit(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    };

    useEffect(() => {
        handledashboard();
        handledesposit(currentPage, 10);
    }, [currentPage]);

    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(data)
                .then(() => {
                    toast.success('Address copied to clipboard!');
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        } else {
            const textArea = document.createElement('textarea');
            textArea.value = data;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            toast.success('Address copied to clipboard (fallback method)!');
        }
    };

    return (
        <>
            <div className="row mt-3">
                <div className="main-qr mt-5 text-center">
                    <div className="mb-3">
                        <h3 className='scan text-center'>Scan the QR code to get address</h3>
                    </div>
                    <div className='qr-2'>
                        {data ? (
                            <QRCode className="w-50" value={data} />
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>
                    <h3 className='scan-2 text-center'>Or</h3>
                    <h3 className='scan-copy text-center mt-2'>
                        {data}
                        <i className="fa-regular fa-copy" onClick={copyToClipboard}></i>
                    </h3>
                    <button className="custom-btn btn-5 d-flex m-auto mt-4" type="button" onClick={handledesposit}>
                        Deposit Now
                    </button>
                </div>
            </div>
        </>
    );
};

export default Deposit;
