import React from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import { useState, useEffect } from 'react'
import { ColorRing } from 'react-loader-spinner';
import businessman from '../assets/img/businessman.png'
import logo from '../assets/img/logo.png'
function Verfication() {
    const { state } = useLocation();
    const [email, setEmail] = useState(state?.email || "");
    const [Otp, setOtp] = useState(new Array(6).fill(''));
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const navigate = useNavigate();
    const handleSignUp = async (e) => {
        e.preventDefault();
        const otpValue = Otp.join('');
        if (!email || otpValue.length !== 6) {
            toast.dismiss();
            toast.error("Please enter the details");
            return;
        }
        setLoadings(true)
        try {
            const signupData = {
                email: email,
                otp: otpValue
            };
            let response = await _fetch(`${base_url}api/user/verify`, 'POST', signupData, {});

            if (response.success === true) {
                toast.success(response.message);
                navigate('/pop');
            } else {
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);
        } finally {
            setLoadings(false);
        }
    }
    const handleresend = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.dismiss();
            toast.error("Please enter the details");
            return;
        }
        setLoading(true);
        try {
            const signupData = {
                email: email,
                type: "resendOtp"

            };
            let response = await _fetch(`${base_url}api/user/resendOtp`, 'POST', signupData, {});

            if (response.success === true) {
                toast.success(response.message);

            } else {
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);
        } finally {
            setLoading(false);
        }
    }
    const handleChange = (element, index) => {
        if (isNaN(element.value)) return; // Ensure input is a number

        // Update OTP array
        let newOtp = [...Otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move focus to the next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData('text').trim(); // Get the pasted data
        if (pastedData.length === Otp.length && !isNaN(pastedData)) {
            const otpArray = pastedData.split('');
            setOtp(otpArray);

            // Automatically fill in each input field and focus the last one
            const inputs = document.querySelectorAll('.otp-input');
            inputs.forEach((input, idx) => {
                input.value = otpArray[idx];
            });
            inputs[Otp.length - 1].focus(); // Focus on the last input
        }
        e.preventDefault(); // Prevent default paste behavior
    };
    return (
        <>
            <div className="conatiner-fluid">
                <div className="d-flex justify-content-center main-box-sing">
                    <div className="sign-page ">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12 sinup-bg'>
                                <div className="heading text-center mb-3">
                                    {/* <NavLink to='/'>   <i class="fa-solid fa-arrow-left dashboard-3 " style={{ alignItems: "center" }}></i> </NavLink> */}
                                    <div className='d-flex mt-2'>

                                        <NavLink to='/'><img className="welcome-2" src={logo} alt="" />

                                        </NavLink>
                                    </div>
                                    {/* <img className="image_border mt-3" src={businessman} alt="..." /> */}
                                    <h3 className='H_3 email' >Account Verification</h3>
                                </div>
                                <form >
                                    {/* <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                        <div class="input_eye">
                                            <div className='in_put_f'>
                                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" readOnly />
                                            </div>
                                            <div className='eye_sec'>
                                                <i class="fa-solid fa-envelope fa-beat-fade"></i>                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="mb-3 bit-ai">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Otp</label>
                                        <div className="otp-input-wrapper" onPaste={handlePaste}>
                                            {/* <input type="text" id="sponser" onChange={(e) => setOtp(e.target.value)} autoComplete='off' className="form-control" /> */}
                                            {Otp.map((data, index) => (
                                                <input
                                                    type="text"
                                                    maxLength="1"
                                                    key={index}
                                                    value={data}
                                                    onChange={(e) => handleChange(e.target, index)}
                                                    onFocus={(e) => e.target.select()}
                                                    className="otp-input"
                                                />
                                            ))}
                                        </div>

                                        <div className='butt'>
                                            <button type="submit" className="custom-btn btn-3 d-flex m-auto" onClick={handleSignUp}>
                                                {loadings ? (
                                                    <ColorRing
                                                        height={20}
                                                        width={20}
                                                        color="#fff"
                                                        ariaLabel="loading"
                                                    />
                                                ) : (
                                                    'Submit'
                                                )}
                                            </button>
                                            <button type="submit" className="custom-btn btn-5 d-flex m-auto " style={{ marginLeft: "150px" }} onClick={handleresend}>

                                                {loading ? (
                                                    <ColorRing
                                                        height={20}
                                                        width={20}
                                                        color="#fff"
                                                        ariaLabel="loading"
                                                    />
                                                ) : (
                                                    'Resend Otp'
                                                )}
                                            </button>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <img className="image_border  slideskew img-fix bitcoin-img sd-shape7 mt-3" src={businessman} alt="..." />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Verfication
