
import React from 'react';
import { NavLink } from 'react-router-dom'
import './navbar.css'
import { FaBars } from "react-icons/fa";
// import './Nabvar.css'  // Add this if you want to add any custom CSS.
import logo from '../assets/img/logo.png'
const Navbar = () => {
  return (
    <div className="navbar_u">
    <nav className="navbar navbar-expand-lg w-100">
     <div className="container">
     <NavLink className='navbar-brand'>
        <img src={logo} alt="" style={{width: "120px",float:'left'}}/>
     </NavLink>
     <button type='button' className='sidebar_btn' data-bs-toggle="collapse" data-bs-target="#collapse_nav">
     <FaBars />
     </button>
       <div className="collapse navbar-collapse" id='collapse_nav'>
       <div className="end_ul ms-auto">
        <ul className="navbar-nav gap-4">
            <li className="nav-item">
                <NavLink className='nav-link hov_links'>Home</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className='nav-link hov_links'>About</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className='nav-link hov_links'>Feature</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className='nav-link hov_links' to="/login">Login</NavLink>
            </li>
            <button type='button' className='btn_signup'>
                <NavLink className="nav-link hov_links text-white" to="/signup">
                    Sign up
                </NavLink>
            </button>
        </ul>
       </div>
       </div>
     </div>
    </nav>
   </div>
  );
};

export default Navbar;
