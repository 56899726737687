import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import group from '../assets/img/group.png'
import home from '../assets/img/home.png'
import integration from '../assets/img/integration.png'
import profileuser from '../assets/img/profileuser.png'
import stockmarket from '../assets/img/stockmarket.png'



const Footer = () => {

    return(<>
        <div className="w3-container">
            <div className="container">
                <div className="row w3-bar w3-black footer-tab mt-5 ">
                <NavLink className="col-2 w3-bar-item w3-button bg-transparent tab-btn text-center" to="/dashboard">
                <img src={home} alt="" className="img-fluid"/>
                <span className="span_up">Home </span>
                </NavLink>
                <NavLink className="col-2 w3-bar-item w3-button bg-transparent tab-btn text-center" to="/bindapi">
                <img src={integration} alt="" className="img-fluid"/>
                <span className="span_up">API</span>
                </NavLink>
                    <NavLink className="col-2 w3-bar-item w3-button bg-transparent tab-btn text-center" to="/open_position">
                <img src={stockmarket} alt="" className="img-fluid"/>
                <span className="span_up">Trades</span>
                </NavLink>

                <NavLink className="col-2 w3-bar-item w3-button bg-transparent tab-btn text-center" to="/team">
                <img src={group} alt="" className="img-fluid"/>
                <span className="span_up">Team</span>
                </NavLink>
                <NavLink className="col-2 w3-bar-item w3-button bg-transparent tab-btn text-center" to="/profile">
                <img src={profileuser} alt="" className="img-fluid"/>
                <span className="span_up">Profile</span>
                </NavLink>
                </div>
            </div>
        </div>
    </>)
}

export default Footer;