import React from 'react'
import logo from '../assets/img/logo.png'

const Footeres = () => {
  return (
    <>
      <div>
        <footer className="main-footer">
          <div className="container">
            <div className="footer-content">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 footer-column">
                  <div className="logo-widget footer-widget">
                    <figure className="logo-box"><a href="#"><img src={logo} alt="" style={{ width: "150px" }} /></a></figure>
                    <div className="text">
                      <p>Lorem ipsum dolor amet consectetur adi pisicing elit sed eiusm tempor incididunt ut labore dolore magna aliqua enim ad minim veniam quis.nostrud exercita.laboris nisi ut aliquip ea commodo conse quatuis aute irure.</p>
                    </div>
                    <ul className="footer-social">
                      <li><a href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li><a href="#"><i className="fab fa-twitter" /></a></li>
                      <li><a href="#"><i className="fab fa-vimeo-v" /></a></li>
                      <li><a href="#"><i className="fab fa-google-plus-g" /></a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 offset-lg-2 footer-column">
                  <div className="service-widget footer-widget">
                    <div className="footer-title">Services</div>
                    <ul className="list">
                      <li><a href="#">Home</a></li>
                      <li><a href="#">About</a></li>
                      <li><a href="#">Feature</a></li>
                      <li><a href="#">Login</a></li>
                      <li><a href="#">Sign Up</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-widget">
                  <div className="contact-widget footer-widget">
                    <div className="footer-title">Contacts</div>
                    <div className="text">
                      <p> Jumeirah Bay Tower, Plot No X3 - Dubai</p>
                      {/* <p>+2(784) 1223323</p>
                      <p>info@example.com</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* main-footer end */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 column">
                <div className="copyright"><a href="#"></a> © 2024 All Right Reserved</div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 column">
                <ul className="footer-nav">
                  <li><a href="#">Terms of Service</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footeres
