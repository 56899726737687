import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import '../assets/css/Style.css';
import '../assets/css/pStyle.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router';
import LogoutModal from '../Auth/LogoutModal';
import logo from '../assets/img/logo.png'
import logo1 from '../assets/img/logo1.png'
import _fetch from '../Config/api'
import { useEffect } from 'react';
import { base_url } from '../Config/config'
import toast from 'react-hot-toast'
const Sidebar = () => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(true);
    const [sidebar, setSidebar] = useState(false);
    const [openSection, setOpenSection] = useState(null);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [name, setName] = useState({});


    const handledashboard = async () => {
        try {
            let response = await _fetch(`${base_url}api/user/dashboard`, 'get', {});
            console.log('resp is ', response)
            if (response.success == true) {

                setName(response?.data.user);
            } else {
                toast.dismiss()
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const logout = () => {
        localStorage.clear('token');
        navigate('/Login');
    };


    const handleSectionToggle = (section) => {
        setOpenSection(openSection === section ? null : section);
    };
    let changeToggle = () => {
        // alert('clicked')
        if (sidebar)
            setSidebar(false)
        else
            setSidebar(true)
    }

    useEffect(() => {

        handledashboard()
    }, [])

    return (
        <>
            <div id="home" className="w3-container city mt-100">
                <div className="navbar-fixed">
                    <div className="navbar__content">
                        <div className="navbar__content-left">
                            <div className="">
                                <NavLink to='/'><img className="welcome" src={logo} alt="" /></NavLink>
                            </div>
                            <div className="">
                                <span className="admin_1 text-dark">Welcome Back!</span>
                                <h6 className="welcome text-light">{name?.name || "N/A"}</h6>
                            </div>

                        </div>
                        <div className="navbar__content-center">
                            <div className="navbar__content-title"></div>
                        </div>
                        <div className="navbar__content-right">
                            <div className="">
                                {/* <i class="fa-solid fa-right-from-bracket" onClick={logout} ></i> */}
                                {/* <i className='btn btn-danger ' onClick={() => setIsLogoutModalOpen(true)} type='button'>LOGOUT</i> */}
                                {/* <button className="btn btn-default" onClick={changeToggle}>
                                    <i className="fas fa-bars text-dark"></i>
                                </button> */}
                                {/* <button className='btn btn-danger ' onClick={() => setIsLogoutModalOpen(true)} type='button'> <i class="fa-solid fa-right-from-bracket"></i></button> */}
                                {/* <LogoutModal
        isOpen={isLogoutModalOpen}
        onCancel={() => setIsLogoutModalOpen(false)}
        onConfirm={() => {
          setIsLogoutModalOpen(false);
          logout();
        }}
      /> */}
                                <div className="content__right">
                                    <div className="d-flex flex-column">
                                        <span>{name?.userId || "N/A"}</span>
                                        <span className='mt-1'>{name?.sponserId || "N/A"}</span>
                                    </div>
                                    {/* <div  class="message">
                  <a href="notificatin.html"><i class="far fa-bell text-light"></i></a> 
              </div> 
              <a href="wallet.html"> <i class="fas fa-wallet text-light"></i></a>*/}
                                    <a href="#menu-toggle" className="btn btn-default" id="menu-toggle">
                                        {" "}
                                        <i className="fas fa-bars text-light" />
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="wrapper" className={sidebar ? "toggled" : ""}>
                    <div id="sidebar-wrapper" className="sidebar s-left">
                        <ul className="sidebar-nav mt-3">
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/open_position">Traders</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/bindapi">Bind Api</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/deposit">Deposit</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/close_position">closeposition</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/activity">Activity</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/openspotposition"> spot position trader</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/closespotposition">close spot position</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/language.png" />

                                    <NavLink to="/deposit">Deposit</NavLink>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                            <li className="text-icon">
                                <div className="main-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/key.png" />
                                    <a href="#">Change password</a>
                                </div>
                                <div className="side-icon">
                                    <img src="https://lexialgo.com/newphone/Assets/img/arrow.png" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Sidebar;
