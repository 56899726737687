import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import moment from 'moment';
import { useNavigate } from 'react-router';
import LogoutModal from '../Auth/LogoutModal';
import { NavLink } from 'react-router-dom';

const Profile = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [phonenumber, SetPhonenumber] = useState('');
  const [name, setName] = useState({});
  const [tag, setTag] = useState('');
  const [url, setUrl] = useState('')
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handledashboard = async () => {
    try {
      let response = await _fetch(`${base_url}api/user/dashboard`, 'get', {});

      if (response.success === true) {
        setData(response?.data?.balance);
        setName(response?.data.user);
        setUrl(response?.data?.url)
        setTag(response?.data)
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  const logout = () => {
    localStorage.clear('token');
    navigate('/');
  };

  useEffect(() => {
    handledashboard();
  }, []);

  const handlephone = async (e) => {
    e.preventDefault();
    try {
      const number = {
        phonenumber: phonenumber
      }


      let response = await _fetch(`${base_url}api/user/addPhoneNumber`, 'post', number, {});

      if (response.success === true) {
        toast.success(response?.message)
        // setName(response?.data.user);
        // setUrl(response?.data?.url)
        // setTag(response?.data)
      } else {
        toast.dismiss()
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }
  // console.log('url',url);
  const copyText = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    toast.dismiss()

    toast.success("Text copied to clipboard!");
  };
  return (
    <>
      <div className="container mt-1 p-1">
        <div className='row text-h2 m-auto padding-form'>

          <h1 className='text-center ' style={{ marginTop: '50px' }}></h1>
          <h3 class="scan text-center">Profile</h3>
          <form>
            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label user-text">Sponser Code </label>
              <input type="text" class="form-control user-text" id="exampleInputPassword1" value={name?.sponserId} />
            </div>

            <div className='ph_sec'>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label user-text">User Id</label>
                <div className='in_put_field d-flex'>
                  <div className='in_in'>
                    <input type="text" class="form-control user-text" id="exampleInputPassword1" value={name?.userId || "N/A"} />
                  </div>
                  <div className='icon_set-22' >
                    <i class="fa-solid fa-id-badge"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className='ph_sec'>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label user-text">Name</label>
                <div className='in_put_field d-flex'>
                  <div className='in_in'>
                    <input type="text" class="form-control user-text" id="exampleInputEmail1" value={name?.name || "N/A"} aria-describedby="emailHelp" />
                    {/* <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> */}
                  </div>
                  <div className='icon_set-22' >
                    <i class="fa-solid fa-user"></i>
                  </div>
                </div>
              </div>
            </div>


            <div className='ph_sec'>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label user-text">Phone Number</label>
                <div className='in_put_field d-flex'>
                  <div className='in_in'>
                    {/* <input type="text" class="form-control user-text" id="exampleInputPassword1"  value={phonenumber} onChange={(e) => SetPhonenumber(e.target.value)} /> */}
                    <input
                      type="text"
                      className="form-control user-text"
                      id="exampleInputPassword1"
                      value={phonenumber}
                      onChange={(e) => SetPhonenumber(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          handlephone();
                        }
                      }}
                    />
                  </div>
                  <div className='icon_set' >
                    <button class="beat-fade" onClick={handlephone} type="submit"><span className='get-otp'>Add Number</span></button>
                  </div>
                </div>

                {/* <button className='btn btn-danger m-auto d-block ' onClick={() => setIsLogoutModalOpen(true)} type='button'>Add</button> */}

              </div>
            </div>


            <div class="mb-3">
              <label for="exampleInputPassword1" class="form-label user-text">Referrals</label>
              <input type="text" class="form-control user-text" id="exampleInputPassword1" value={tag?.directsCount} />
            </div>

            <div class="mb-3">
              <div className='in_put_field d-flex'>
                <div className='in_in'>
                  <label for="exampleInputPassword1" class="form-label user-text">Referral Link</label>
                  <input type="text" class="form-control user-text" id="exampleInputPassword1" value={url} />
                </div>

                <div className='icon_set-2' >
                  <i class="fa-solid fa-copy fa-fade" onClick={() => copyText(url)} ></i>
                </div>
              </div>
            </div>
            <div class="mb-3 d-flex">
              <button className='btn btn-danger m-auto d-block ' onClick={() => setIsLogoutModalOpen(true)} type='button'>Logout</button>
              <button className='btn btn-danger m-auto d-block ' type='button'>Submit</button>

              <button className='btn btn-danger m-auto d-block ' type='button' > <NavLink className='set-text' to='/ticket'>Ticket</NavLink></button>

            </div>


          </form>
        </div >
      </div >

      <LogoutModal
        isOpen={isLogoutModalOpen}
        onCancel={() => setIsLogoutModalOpen(false)}
        onConfirm={() => {
          setIsLogoutModalOpen(false);
          logout();
        }}
      />

    </>
  )
}

export default Profile
