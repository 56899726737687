import React from 'react'
import popup from '../assets/img/popup.png'
import { NavLink, useNavigate } from 'react-router-dom'

const Pop = () => {

  // const { userData } = location.state || {}


  return (

    <>
      <div className="page">
        <div className="container">
          <div className="row  ` ">

            <div className="col-lg-6 col-md-6 col-sm-12 col-12 m-auto">
              {/* <div className='login-form'> */}

              <form className="login-formi main-success mt-4">
                <div className="mb-3 bg">
                  <div className='arrow_icon'>

                    <img src={popup} alt='arrow-icon' />
                    <br />



                    {/* {userData && ( */}
                    <div>
                      {/* <h2>Welcome, {userData.message}!</h2> */}

                      <h5 className='success-h5'> Congratulations your account has been successfully Created </h5>

                      <p className='success-p'>User id: </p>
                      <p className='success-p'>Password:</p>
                      {/* <p>Transaction Password: {userData.txn_password}</p> */}

                      {/* Add more fields as needed */}
                    </div>
                    {/* )} */}
                    {/* <div className="now mt-4 d-flex justify-content-center">
                             <NavLink to='/login' className='link'> <button type="submit" className="login-button" >Continue</button></NavLink>
                            </div> */}
                    {/* <button type="submit" className="login-button" > <NavLink to='/login' style={{color:"white"}}>Continue</NavLink></button> */}
                    <NavLink to='/login' style={{ color: "white" }}>        <button type="submit" className="login-button mt-3" >Login</button></NavLink>
                  </div>
                </div>
              </form>

              {/* </div> */}


            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pop
