
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import dateFormat from "dateformat";
const ClosePosition = () => {
    const [data, setData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currency, setCurrency] = useState('');
    const [userId, setUserId] = useState('');

    const handledashboard = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize;
            setpageSer(skip);
            const requestData = {
                limit: pageSize,
                skip: skip,
                type: "FUTURE",
                from: startDate,
                to: endDate,
                currency: currency,
                userId: userId,
            };

            let response = await _fetch(`${base_url}api/user/orders`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }


    useEffect(() => {
        handledashboard(currentPage, 10);
    }, [currentPage]);
    const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);

    };


    const records = () => {
        if (data && data?.data && data?.data?.length > 0) {
            return data?.data?.map((row, i) => {
                const serialNumber = pageSer + i + 1;
                return (



                    <div key={i} className="container mt-5">
                        <div className="card border-primary">
                            <div className="card-header bg-white d-flex justify-content-between">
                                <span className="badge bg-success">{serialNumber}</span> {row?.symbol}
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-4">
                                        <dl className="row mb-0 openPositionsDiv">
                                            <dt className="col-sm-5">OrderId:</dt>
                                            <dd className="col-sm-7">{row?.orderId}</dd>
                                            <dt className="col-sm-5">Symbol:</dt>
                                            <dd className="col-sm-7">{row?.symbol}</dd>
                                            <dt className="col-sm-5">status:</dt>
                                            <dd className="col-sm-7">{row?.status}</dd>
                                            <dt className="col-sm-5">Order Price:</dt>
                                            <dd className="col-sm-7">{row?.price}</dd>
                                            <dt className="col-sm-5">UserId:</dt>
                                            <dd className="col-sm-7">{row?.userId?.userId}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-4">
                                        <dl className="row mb-0 openPositionsDiv ">
                                            <dt className="col-sm-5">OrigQty</dt>
                                            <dd className="col-sm-7">{row?.origQty}</dd>
                                            <dt className="col-sm-5">Type</dt>
                                            <dd className="col-sm-7 text-danger">{row?.type}</dd>
                                            <dt className="col-sm-5">Side</dt>
                                            <dd className="col-sm-7">{row?.side}</dd>
                                            <dt className="col-sm-5">Quantity:</dt>
                                            <dd className="col-sm-7 text-secondary">{row?.quantity}</dd>
                                            <dt className="col-sm-5">OrderType:</dt>
                                            <dd className="col-sm-7">{row?.orderType}</dd>
                                        </dl>
                                    </div>
                                    <div className="col-4">
                                        <dl className="row mb-0 openPositionsDiv ">
                                            <dt className="col-sm-5">ExchangeName:</dt>
                                            <dd className="col-sm-7">{row?.exchangeName}</dd>
                                            <dt className="col-sm-5">BatchId</dt>
                                            <dd className="col-sm-7 text-danger">{row?.batchId}</dd>
                                            <dt className="col-sm-5">Date:</dt>
                                            <dd className="col-sm-7">{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                                        </dl>
                                    </div>
                                    {/* <div className="d-grid gap-2 d-md-flex mt-2">
                                        <button className="btn btn-danger" type="button">Close</button>
                                        <button className="btn btn-primary" type="button">View</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                );
            });
        } else {
            return <div >
                <p className='text-center'>Data Not Found</p>
            </div>;
        }
    };

    const resetFilters = () => {
        setStartDate('');
        setEndDate('');
        setCurrency('');
        setUserId('');
        // Reset filtered data to show all data again
        handledashboard(currentPage, 10); // Optionally fetch all data again
    };


    return (
        <>

            {/* <div className="section2">

                <div className="table-sec">
                    <div className="transactions">
                        <div className="container mt-5">
                            <div className="card">
                                <div className='row'>
                                    <div className='main-12'>

                                        <div className=''>
                                        <input type='date' className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                    </div>
                                    <div className=''>
                                        <input type='date' className='form-control' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                    </div>
                                       
                                    </div>



                                    <div className="main-table-2 mt-3">
                                        <div className=''>
                                        <select name="currency" className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                            <option value="">Select Currency</option>
                                            <option value="XRP">XRP</option>
                                            <option value="BTC">BTC</option>
                                            <option value="BNB">BNB</option>
                                            <option value="SHIB">SHIB</option>
                                        </select>
                                    </div>
                                         <div className=''>
                                        <input type="text" placeholder="User Id Search" value={userId} onChange={(e) => setUserId(e.target.value)} />
                                    </div>






                                        
                                        <div className="filter-btn">
                                             <button onClick={() => handledashboard(1, 10)}>Apply Filters</button>
                                        <button onClick={resetFilters} className="btn btn-secondary">Reset Filters</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {records()}

                        <div className="pagination mb-3 mt-3">
                            <Pagination
                                style={{ cursor: "pointer" }}
                                className="mx-auto"
                                current={currentPage || 1}
                                total={data?.count || 0}
                                pageSize={10}
                                onChange={handlePaginationChange}
                                showSizeChanger={false}
                                showQuickJumper={false}
                            />

                        </div>
                    </div>

                </div>
            </div> */}



            <>
                <div className="section2">
                    <div className="table-sec mt-4">
                        <div className="transactions">
                            <div className="container mt-5">
                                <div className="card back-card">
                                    <div className='main-12'>
                                        <div className='row'>

                                            <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                                                <input type='date' className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                                                <input type='date' className='form-control' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                                                <select name="currency" className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                                    <option value="">Select Currency</option>
                                                    <option value="XRP">XRP</option>
                                                    <option value="BTC">BTC</option>
                                                    <option value="BNB">BNB</option>
                                                    <option value="SHIB">SHIB</option>
                                                </select>
                                            </div>


                                            <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
                                                <div className='search-btn'>
                                                    <input type="text" placeholder="User Id Search" value={userId} onChange={(e) => setUserId(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
                                                <div className='reset-btn'>
                                                    <button className='apply-btn' onClick={() => handledashboard(1, 10)}>Apply Filters</button>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-6 col-6 mt-1 p-1'>
                                                <div className='place-2'>
                                                    <button onClick={resetFilters} className="btn btn-secondary">Reset Filters</button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>


                            {records()}

                            <div className="pagination mb-3 mt-3">
                                <Pagination
                                    style={{ cursor: "pointer" }}
                                    className="mx-auto"
                                    current={currentPage || 1}
                                    total={data?.count || 0}
                                    pageSize={10}
                                    onChange={handlePaginationChange}
                                    showSizeChanger={false}
                                    showQuickJumper={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
            );

        </>
    )
}

export default ClosePosition
