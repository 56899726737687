import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { NavLink, useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import moment from 'moment';
import enter from '../../src/assets/img/enter.png'
import copy from '../../src/assets/img/copy.png'

import { Pagination } from "antd";

const Wallet = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
       

      };

      let response = await _fetch(`${base_url}api/user/walletLedgar`, 'post', requestData);
      console.log('response ', response)
      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }




  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };
  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <>
            <div className="container p-2">
              <div className="card p-2">

                {/* <div className="card-header bg-white">
                  <button className={(row?.side == 'BUY') ? 'badge btn  bg-success' : 'badge btn  bg-danger'} style={{ float: 'left' }}>{row?.side} </button>
                  <p className='text-center' style={{ left: '24%', top: '9px', position: 'absolute' }}>{row?.exchangeName}</p>
                  <p className='text-center' style={{ right: '0px', top: '7px', position: 'absolute' }}>{row?.symbol} <img src={enter} onClick={() => handleStatusChange(row._id, 'openview')} style={{ height: '40px' }} /> </p>
                  <p className='text-center' style={{ right: '0px', top: '9px', position: 'absolute' }}></p>

                </div> */}
                <div className="card-body">
                  <div className="row openPositionsDiv">
                    <div className="col-6">
                      <dl className="row mb-0 positionRow">
                        <dt className="col-sm-6">Token Name:</dt>
                        <dd className="col-sm-6"><small>{row?.tokenName}</small></dd>
                        <dt className="col-sm-6">Amount:</dt>
                        <dd className="col-sm-6">{row?.amount}</dd>
                        <dt className="col-sm-6">Created At:</dt>
                        <dd className="col-sm-6">{dateFormat(row?.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd>
                       
                      </dl>
                      
                    </div>
                    <div className="col-6">
                      <dl className="row mb-0 positionRow">
                        <dt className="col-sm-6">Side</dt>
                        <dd className={`col-sm-6 ${row?.side === 'DEBIT' ? 'text-danger' : 'text-success'}`}>
  {row?.side}
</dd>
                        <dt className="col-sm-6">Transaction Type :</dt>
                        <dd className="col-sm-6">{row?.transactionType?.toUpperCase()}</dd>
                        {/* <dt className="col-sm-6">Updated At:</dt>
                        <dd className="col-sm-6">{dateFormat(row?.updatedAt, "yyyy-mm-dd, hh:mm:ss TT")}</dd> */}
                      </dl>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>


            
          </>

        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13" className='text-center'>Data Not Found</td>
        </tr>
      );
    }
  };
  return (
    <>
<div className="section2">

<div className="table-sec">
  <div className="transactions">
  <div className="container mt-5">
              <div className="card start-date">
                <div className='row'>
                  



                 

                </div>
              </div>
            </div>

    {records()}

    <div className="pagination">
      <Pagination
        style={{ cursor: "pointer" }}
        className="mx-auto"
        current={currentPage || 1}
        total={data?.count || 0}
        pageSize={10}
        onChange={handlePaginationChange}
        showSizeChanger={false}
        showQuickJumper={false}
      />

    </div>
  </div>

</div>
</div>
    </>
  )
}

export default Wallet
