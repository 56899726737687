// import React, { useState } from 'react'
// import '../assets/css/Style.css'
// import { NavLink, useNavigate } from 'react-router-dom'
// import { base_url } from '../Config/config'
// import _fetch from '../Config/api'
// import toast from 'react-hot-toast'
// import { ColorRing } from 'react-loader-spinner';
// import businessman from '../assets/img/businessman.png'
// import logo from '../assets/img/logo.png'
// const otp = () => {
//   const [email, setEmail] = useState()
//   const [password, setPassword] = useState()
//   const [showPassword, setShowPassword] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     // console.log("email", email);
//     // console.log("password", password);
//     if (!email || !password) {
//       toast.dismiss()
//       toast.error("Please enter the details");
//       return;
//     }
//     setLoading(true);
//     try {
//       const requestData = {
//         email, password,
//       };
//       let response = await _fetch(`${base_url}api/user/signIn`, 'POST', requestData, {})

//       // console.log("data", response.data?.accessToken);
//       if (response.success === true) {


//         // localStorage.setItem("token", response.token)
//         // console.log('token', response.data?.token);

//         toast.success(response.message)
//         navigate('/Verfications', { state: { email: email } });
//         // navigate('/dashboard')
//       } else {
//         toast.error(response.message)
//       }

//     }
//     catch (error) {
//       console.error('Error details:', error);
//     } finally {
//       setLoading(false);
//     }
//   }


//   return (
//     <>
//       <div className="conatiner-fluid">
//         <div className="d-flex justify-content-center main-box-sing">
//           <div className="login-page ">
//             <div className="row">
//               <div className='col-lg-6 col-md-6 col-sm-6 col-12 sinup-bg'>

//                 <div className="heading text-center ">
//                   {/* <i class="fa-solid fa-arrow-left"></i> */}
//                   {/* <h3>Future Trade</h3> */}
//                   {/* <NavLink to='/'>   <i class="fa-solid fa-arrow-left dashboard-3 " style={{ alignItems: "center" }}></i> </NavLink> */}
//                   <div className='d-flex mt-2'>

//                     <NavLink to='/'><img className="welcome-2" src={logo} alt="" />

//                     </NavLink>


//                   </div>
//                   {/* <NavLink to='/'><img className="welcome" src={logo} alt="" /> 
//                                 <i class="fa-solid fa-arrow-left"></i>
//                                 </NavLink> */}

//                   {/* <img className="image_border mt-3" src={businessman} alt="..." /> */}


//                 </div>
//                 <form autocomplete="off" onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputEmail1" className="form-label ">Email </label>
//                     <div class="input_eye">
//                       <div className='in_put_f'>
//                         <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control email-section" autoComplete='off' aria-describedby="emailHelp" />
//                       </div>
//                       <div className='eye_sec'>
//                         <i class="fa-solid fa-envelope fa-beat-fade"></i>                                            </div>
//                     </div>
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
//                     {/* <input type="password" id='password' value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" /> */}

//                     <div class="input_eye">
//                       <div className='in_put_f'>
//                         <input
//                           type={showPassword ? 'text' : 'password'}
//                           id='password'
//                           value={password}
//                           onChange={(e) => setPassword(e.target.value)}
//                           className="form-control "
//                         />

//                       </div>

//                       <div className='eye_sec'>
//                         <span
//                           className={`password-toggle ${showPassword ? 'active' : ''}`}
//                           onClick={() => setShowPassword(!showPassword)}

//                         >
//                           {showPassword ? (
//                             <i className="fas fa-eye-slash"></i>
//                           ) : (
//                             <i className="fas fa-eye"></i>
//                           )}
//                         </span>
//                       </div>

//                     </div>


//                   </div>
//                   <div className="mb-3">
//                     <div className="forget fw-bold mt-2 pt-1 mb-0 text-right">
//                       <div className="">
//                         < NavLink to="/Verfication_forget" className="link-white" >Forgot Password?</NavLink>
//                       </div>
//                     </div>
//                   </div>
//                   <button class="custom-btn btn-3 d-flex m-auto"><span>Submit</span>
//                     {/* <button type="submit" className="btn btn-primary d-flex m-auto"> */}
//                     {loading ? (
//                       <ColorRing
//                         height={20}
//                         width={20}
//                         color="#fff"
//                         ariaLabel="loading"
//                       />
//                     ) : (
//                       ''
//                     )}</button>

//                   <div>
//                     <p className="smalls fw-bold pt-1 mb-0 text-center">
//                       Don't have an account? <NavLink to="/signup" className="link-white">Sign Up</NavLink>
//                     </p>
//                   </div>
//                 </form>
//               </div>

//               <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
//                 <img className="image_border  slideskew img-fix bitcoin-img sd-shape7 mt-3" src={businessman} alt="..." />

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default otp



import React, { useState } from 'react'
import logo from '../assets/img/logo.png'
import { base_url } from '../Config/config'
import _fetch from '../Config/api'
import toast from 'react-hot-toast'
import { useRef } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import businessman from '../assets/img/businessman.png'
const Pageotp = () => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const location = useLocation();
  const navigate = useNavigate();

  // Destructure the data passed from the signup page
  const { email, password } = location.state || {};
  const inputRefs = [useRef(null), useRef(null), useRef(null),useRef(null),useRef(null),useRef(null)];
  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    const enteredOtp = otp.join('');
    if (enteredOtp.length !== 6) {
        toast.error('Please enter a valid 6-digit OTP');
        return;
    }

    try {
        const requestData = {
            email,
            password,
            otp: enteredOtp,
        };

        const response = await fetch(`${base_url}api/register`, {
            method: 'POST',
           
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        const data = await response.json();
        console.log("Registration API Response:", data);

        if (data.success === true) {
            toast.dismiss();
            // toast.success(data.message);
            // navigate('/login'); // Redirect to login page on success
            navigate('/information', { state: { userData: data } });
        } else {
            toast.dismiss();
            toast.error(data.error);
        }
    } catch (error) {
        console.error('Error during registration:', error);
    }
};
const handleOtpChange = (index, value) => {
  const updatedOtp = [...otp];
  updatedOtp[index] = value;
  setOtp(updatedOtp);

  if (value.length === 1) {
      focusNext(index);
  }
};
const focusNext = (index) => {
  if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
  }
};
const handlePaste = (e) => {
  const pastedValue = e.clipboardData.getData('text');
  const otpArray = pastedValue.split('').slice(0, 6); 
  const updatedOtp = [...otpArray, ...otp.slice(otpArray.length)]; 
  setOtp(updatedOtp);

  // Focus on the next empty input
  for (let i = 0; i < updatedOtp.length; i++) {
      if (updatedOtp[i] === '') {
          inputRefs[i].current.focus();
          break;
      }
  }
};
  return (
    <>
      <div className="conatiner-fluid">
        <div className="d-flex justify-content-center main-box-sing">
          <div className="login-page ">
            <div className="row">
              <div className='col-lg-6 col-md-6 col-sm-6 col-12 sinup-bg'>
                <div className="heading text-center ">
                  {/* <i class="fa-solid fa-arrow-left"></i> */}
                  {/* <h3>Future Trade</h3> */}
                  {/* <NavLink to='/'>   <i class="fa-solid fa-arrow-left dashboard-3 " style={{ alignItems: "center" }}></i> </NavLink> */}
                  <div className='d-flex mt-2'>

                    <NavLink to='/'><img className="welcome-2" src={logo} alt="" />

                    </NavLink>


                  </div>
                  {/* <NavLink to='/'><img className="welcome" src={logo} alt="" /> 
                                <i class="fa-solid fa-arrow-left"></i>
                                </NavLink> */}

                  {/* <img className="image_border mt-3" src={businessman} alt="..." /> */}


                </div>
                <form className="otp-form text-center">
                  <div className="mb-3 otp-ju">
                    <div className="heading mt-4">
                      <h4 className="bep_2">Enter OTP</h4>
                    </div>
                    <div className="mt-4 bit-ai">
                    {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        ref={inputRefs[index]}
                                        type="text"
                                        className="otp-input"
                                        maxLength={1}
                                        value={digit}
                                        onInput={(e) => handleOtpChange(index, e.target.value)}
                                        onPaste={handlePaste} // Handle paste
                                        style={styles.input}
                                    />
                                ))}
                      {/* <input
                        type="text"
                        className="otp-input"
                        maxLength={1}
                        defaultValue=""
                        style={{
                          width: 35,
                          height: 35,
                          fontSize: 15,
                          textAlign: "center",
                          marginRight: 10
                        }}
                      />
                      <input
                        type="text"
                        className="otp-input"
                        maxLength={1}
                        defaultValue=""
                        style={{
                          width: 35,
                          height: 35,
                          fontSize: 15,
                          textAlign: "center",
                          marginRight: 10
                        }}
                      />
                      <input
                        type="text"
                        className="otp-input"
                        maxLength={1}
                        defaultValue=""
                        style={{
                          width: 35,
                          height: 35,
                          fontSize: 15,
                          textAlign: "center",
                          marginRight: 10
                        }}
                      />
                      <input
                        type="text"
                        className="otp-input"
                        maxLength={1}
                        defaultValue=""
                        style={{
                          width: 35,
                          height: 35,
                          fontSize: 15,
                          textAlign: "center",
                          marginRight: 10
                        }}
                      />
                      <input
                        type="text"
                        className="otp-input"
                        maxLength={1}
                        defaultValue=""
                        style={{
                          width: 35,
                          height: 35,
                          fontSize: 15,
                          textAlign: "center",
                          marginRight: 10
                        }}
                      />
                      <input
                        type="text"
                        className="otp-input"
                        maxLength={1}
                        defaultValue=""
                        style={{
                          width: 35,
                          height: 35,
                          fontSize: 15,
                          textAlign: "center",
                          marginRight: 10
                        }}
                      /> */}
                    </div>
                    <div className="ios mt-4">
                      <button type="submit" className="custom-btn btn-3 d-flex m-auto">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                <img className="image_border  slideskew img-fix bitcoin-img sd-shape7 mt-3" src={businessman} alt="..." />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

const styles = {
  input: {
      width: '40px',
      height: '40px',
      fontSize: '18px',
      textAlign: 'center',
      marginRight: '10px',
  },
};

export default Pageotp
