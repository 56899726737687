import React from 'react'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'
import _fetch from '../Config/api'
import { base_url } from '../Config/config'
import { useState, useEffect } from 'react'
import { ColorRing } from 'react-loader-spinner';
import businessman from '../assets/img/businessman.png'
import logo from '../assets/img/logo.png'
function Verfication_forget() {
    const { state } = useLocation();
    const [email, setEmail] = useState(state?.email || "");

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleforget = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.dismiss();
            toast.error("Please enter the details");
            return;
        }
        setLoading(true);
        try {
            const forgetData = {
                email: email,
                type: "Forgetotp"

            };
            let response = await _fetch(`${base_url}api/user/resendOtp`, 'POST', forgetData, {});

            if (response.success === true) {
                toast.success(response.message);
                // navigate('/Forget', { state: { email: email } });
                navigate('/Forget', { state: { email: email } });
            } else {
                toast.error(response.message);
            }
        }
        catch (error) {
            console.log("Error details", error);
        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <div className="conatiner-fluid io">
                <div className="d-flex justify-content-center main-box-sing">
                    <div className="sign-page ls ">
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12 sinup-bg'>
                                <div className="heading text-center  ">
                                    {/* <NavLink to='/'>   <i class="fa-solid fa-arrow-left dashboard-3 " style={{ alignItems: "center" }}></i> </NavLink> */}
                                    <div className='d-flex mt-2'>

                                        <NavLink to='/'><img className="welcome-2" src={logo} alt="" />

                                        </NavLink>
                                    </div>
                                    {/* <img className="image_border mt-3" src={businessman} alt="..." /> */}
                                    <h3 className='H_3 email'>Email Confirmation</h3>
                                </div>
                                <form >
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label">Email </label>
                                        <div class="input_eye">
                                            <div className='in_put_f'>
                                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete='off' className="form-control" aria-describedby="emailHelp" />
                                            </div>

                                            <div className='eye_sec'>
                                                <i class="fa-solid fa-envelope fa-beat-fade"></i>                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" className="custom-btn btn-3 d-flex m-auto" onClick={handleforget}>

                                        {loading ? (
                                            <ColorRing
                                                height={20}
                                                width={20}
                                                color="#fff"
                                                ariaLabel="loading"
                                            />
                                        ) : (
                                            'Resend OTP'
                                        )}
                                    </button>
                                    <div>

                                    </div>
                                </form>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <img className="image_border  slideskew img-fix bitcoin-img sd-shape7 mt-3" src={businessman} alt="..." />

                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </>
    )
}

export default Verfication_forget
