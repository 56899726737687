import React, { useState } from 'react'
import '../assets/css/Style.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { base_url } from '../Config/config'
import _fetch from '../Config/api'
import toast from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner';
import businessman from '../assets/img/businessman.png'
import logo from '../assets/img/logo.png'
const Login = () => {
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        alert()
        e.preventDefault();
        // console.log("email", email);
        // console.log("password", password);
        if (!email || !password) {
            toast.dismiss()
            toast.error("Please enter the details");
            return;
        }
        setLoading(true);
        try {
            const requestData = {
                email,
                password,
            };
            let response = await _fetch(`${base_url}api/user/signIn`, 'POST', requestData, {})

            // console.log("data", response.data?.accessToken);
            if (response.success === true) {


                // localStorage.setItem("token", response.token)
                // console.log('token', response.data?.token);
                toast.dismiss();
                toast.success(response.message)
                navigate('/Verfications', { state: { email, password } });
                // navigate('/dashboard')
            } else {
                toast.error(response.message)
            }

        }
        catch (error) {
            console.error('Error details:', error);
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <div className="conatiner-fluid">
                <div className="d-flex justify-content-center main-box-sing">
                    <div className="login-page ">
                        <div className="row">
                            <div className='col-lg-6 col-md-6 col-sm-6 col-12 sinup-bg'>

                                <div className="heading text-center ">
                                    {/* <i class="fa-solid fa-arrow-left"></i> */}
                                    {/* <h3>Future Trade</h3> */}
                                    {/* <NavLink to='/'>   <i class="fa-solid fa-arrow-left dashboard-3 " style={{ alignItems: "center" }}></i> </NavLink> */}
                                    <div className='d-flex mt-2'>

                                        <NavLink to='/'><img className="welcome-2" src={logo} alt="" />

                                        </NavLink>


                                    </div>
                                    {/* <NavLink to='/'><img className="welcome" src={logo} alt="" /> 
                                <i class="fa-solid fa-arrow-left"></i>
                                </NavLink> */}

                                    {/* <img className="image_border mt-3" src={businessman} alt="..." /> */}


                                </div>
                                <form autocomplete="off" onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label ">Email </label>
                                        <div class="input_eye">
                                            <div className='in_put_f'>
                                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control email-section" autoComplete='off' aria-describedby="emailHelp" />
                                            </div>
                                            <div className='eye_sec'>
                                                <i class="fa-solid fa-envelope fa-beat-fade"></i>                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                        {/* <input type="password" id='password' value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" /> */}

                                        <div class="input_eye">
                                            <div className='in_put_f'>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    id='password'
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    className="form-control "
                                                />

                                            </div>

                                            <div className='eye_sec'>
                                                <span
                                                    className={`password-toggle ${showPassword ? 'active' : ''}`}
                                                    onClick={() => setShowPassword(!showPassword)}

                                                >
                                                    {showPassword ? (
                                                        <i className="fas fa-eye-slash"></i>
                                                    ) : (
                                                        <i className="fas fa-eye"></i>
                                                    )}
                                                </span>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="mb-3">
                                        <div className="forget fw-bold mt-2 pt-1 mb-0 text-right">
                                            <div className="">
                                                < NavLink to="/Verfication_forget" className="link-white" >Forgot Password?</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="custom-btn btn-3 d-flex m-auto" type='submit'><span >Submit</span>
                                        {/* <button type="submit" className="btn btn-primary d-flex m-auto"> */}
                                        {loading ? (
                                            <ColorRing
                                                height={20}
                                                width={20}
                                                color="#fff"
                                                ariaLabel="loading"
                                            />
                                        ) : (
                                            ''
                                        )}</button>

                                    <div>
                                        <p className="smalls fw-bold pt-1 mb-0 text-center">
                                            Don't have an account? <NavLink to="/signup" className="link-white">Sign Up</NavLink>
                                        </p>
                                    </div>
                                </form>
                            </div>

                            <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                                <img className="image_border  slideskew img-fix bitcoin-img sd-shape7 mt-3" src={businessman} alt="..." />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login