import React from 'react'
import './footer.css'
import { AiTwotoneMessage } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import { CiLocationArrow1 } from "react-icons/ci";
import {Formik, Field, Form, ErrorMessage} from 'formik'
import { object, string, number, date, InferType } from 'yup';
import { NavLink } from 'react-router-dom';
import { CiFacebook } from "react-icons/ci";
import { FaTwitter } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";

const Footer = () => {

    const initialVals = {
        username: " ",
        email: " "
    }
    const formvalidateSc = object({
        usernamename: string().required("please enter your name"),
        email: string().email("please enter your email")
      });
      

    const handleSubmit = (ev) =>{
        console.log(ev);
        
    }

  return (
   <>
   <div className="footer bg-light mt-5">
    <footer>
        <div className="container">
    <div className="in_touch text-center">
        <div className='pb-2'>CONTACT</div>
        <h2 className='fw-bold'>Get In Touch        </h2>
        <p className="text-muted pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.

</p>
    </div>
    <div className="flex_contact mt-2 pt-4 pb-4">
    <div className="cont gap-2">
    <AiTwotoneMessage size={34} color='skyblue'/>
     info@example.com
    </div>
    <div className="cont gap-2">
    <IoCallOutline color='skyblue' size={34}/>

     +1 056030232
    </div>
    <div className="cont gap-2">
    <CiLocationArrow1 color='skyblue' size={34}/>

     info@example.com
    </div>


    </div>

    <div className="formik">
   <div className="row justify-content-center">
    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialVals}
      validationSchema={formvalidateSc}
    >
        <Form>
            <p className="pb-2 text-muted">Your Name</p>
            <Field className="form-control" name="username"/>
            <p className="text-danger">{<ErrorMessage name='username'/>}</p>

            <p className="pb-2 text-muted">Your Email</p>
            <Field className="form-control" name="email"/>
            <p className="text-danger">{<ErrorMessage name='email'/>}</p>

           <textarea cols={10} rows={12} name="" id="" className='form-control'></textarea>

            <button type='button' className='btn_connect_wallet ps-4 pe-4 mt-3'>Submit</button>
        </Form>
    </Formik>
    </div>
   </div>
    </div>

<div className="reserved pt-3 pb-3">
    <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-12 mt-2">
            <p>Ⓒ future-trade.io all Rights Reserved. Designed By 
                <strong>M</strong>
            </p>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-12 mt-2 d-flex gap-3 ms-auto">
            <NavLink to="" className="footer_icn">
            <CiFacebook size={20}/>
            </NavLink>
            <NavLink to="" className="footer_icn">
            <CiLinkedin size={20}/>
            </NavLink>
            <NavLink to="" className="footer_icn">
            <FaTwitter size={20}/>
            </NavLink>
        </div>
    </div>
</div>


        </div>
    </footer>
   </div>
   </>
  )
}

export default Footer