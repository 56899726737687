import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import dateFormat from "dateformat";
import { useLocation } from 'react-router-dom';
import { Pagination } from "antd";
const Openspotview = () => {
    const [data, setData] = useState();
    const location = useLocation(); 
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSer, setpageSer] = useState(0); 
console.log('data',data);

    
    const getQueryParams = (search) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);
    const id = queryParams.get('id');  

    console.log('id', id); 

    const handledashboard = async (page = 1, pageSize = 10) => {
        try {
            const skip = (page - 1) * pageSize;
            setpageSer(skip);
            const requestData = {
              limit: pageSize,
              skip: skip,
              id: id 
            };
           

            let response = await _fetch(`${base_url}api/user/tradeDetails`, 'post', requestData);

            if (response.success === true) {
                setData(response);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.log("Error details", error);
        }
    }

    useEffect(() => {
        handledashboard(currentPage, 10);
      }, [currentPage]);
      const handlePaginationChange = (pageOne) => {
        setCurrentPage(pageOne);
       
      };

    const records = () => {
        if (data && data?.data && data?.data?.length > 0) {
          return data?.data?.map((row, i) => {
            const serialNumber = pageSer + i + 1;
            return (
              <tr className="table-row" key={i}>
                <td className="tabel-text w inter">{serialNumber}</td>
                <td className="tabel-text w inter">{row?.orderId}</td>
                <td className="tabel-text w inter">{row?.symbol}</td>
                <td className="tabel-text w inter">{row?.status}</td>
                <td className="tabel-text w inter">{row?.price}</td>
                {/* <td className="tabel-text w inter">{row?.quantity}</td> */}
                {/* <td className="tabel-text w inter">{row?.origQty}</td> */}
                <td className="tabel-text w inter">{row?.type}</td>
                <td className="tabel-text w inter">{row?.side}</td>
                <td className="tabel-text w inter">{row?.quantity}</td>
                <td className="tabel-text w inter">{row?.batchId}</td>
    
                <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
              
    
    
              </tr>
            );
          });
        } else {
          return (
            <tr>
              <td colSpan="13">Data Not Found</td>
            </tr>
          );
        }
      };
    return (
        <>
             <div className="section2">

<div className="table-sec">
  <div className="transactions">
    <div className='op'>

      <table className="table">
        <thead>
          <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Order Id</th>
          <th scope="col">Pair</th>
          <th scope="col">Status</th>
          <th scope="col">Price</th>
          {/* <th scope="col">quantity</th> */}
          <th scope="col">Type</th>
          <th scope="col">Mode</th>
          <th scope="col">Quantity</th>

          <th scope="col">Batch Id</th>
          {/* <th scope="col">quantity</th> */}

          <th scope="col">Date</th>
          </tr>
        </thead>
        <tbody>
          {records()}
        </tbody>
      </table>
    </div>
    <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
  </div>

</div>
</div>
        </>
    )
}

export default Openspotview
