import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import { ColorRing } from 'react-loader-spinner';
import { NavLink, useNavigate } from 'react-router-dom';
import dateFormat from "dateformat";
import moment from 'moment';
import enter from '../../src/assets/img/enter.png'
import copy from '../../src/assets/img/copy.png'

import { Pagination } from "antd";
const OpenPosition = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);
  const navigate = useNavigate();
  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        type: "FUTURE",

      };

      let response = await _fetch(`${base_url}api/user/trades`, 'post', requestData);
      console.log('response ', response)
      if (response.success === true) {
        setData(response);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }




  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };


  const handleStatusChange = (userId, selectedValue) => {
    let url = "";

    switch (selectedValue) {
      case "openview":
        url = `/openview?id=${userId}`;
        break;

      default:
        return;
    }


    navigate(url);
  };

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <>
            <div className="container p-2">
              <div className="card p-2">

                <div className="card-header bg-white">
                  <button className={(row?.side == 'BUY') ? 'badge btn  bg-success' : 'badge btn  bg-danger'} style={{ float: 'left' }}>{row?.side} </button>
                  <p className='text-center' style={{ left: '24%', top: '14px', position: 'absolute' }}>{row?.exchangeName}</p>
                  <p className='text-center' style={{ right: '0px', top: '10px', position: 'absolute' }}>{row?.symbol} <img src={enter} onClick={() => handleStatusChange(row._id, 'openview')} style={{ height: '35px' }} /> </p>
                  <p className='text-center' style={{ right: '0px', top: '9px', position: 'absolute' }}></p>

                </div>
                <div className="card-body">
                  <div className="row openPositionsDiv">
                    <div className="col-4">
                      <dl className="row mb-0 positionRow">
                        <dt className="col-sm-6">User Id:</dt>
                        <dd className="col-sm-6"><small>{row?.orderId}</small></dd>
                        <dt className="col-sm-6">status:</dt>
                        <dd className="col-sm-6">{row?.status}</dd>
                        <dt className="col-sm-6">{moment(row.createdAt).format("YY-MM-DD")}</dt>
                        <dd className="col-sm-6">{moment(row.createdAt).format("hh:mm:ss")}</dd>
                      </dl>
                      {/* <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button> */}
                    </div>
                    <div className="col-4">
                      <dl className="row mb-0 positionRow">
                        <dt className="col-sm-6">User Name</dt>
                        <dd className="col-sm-6">{row?.userId?.name}</dd>
                        <dt className="col-sm-6">Leverage :</dt>
                        <dd className="col-sm-6">{row?.leverage}X</dd>
                        <dt className="col-sm-6">Type:</dt>
                        <dd className="col-sm-6 text-secondary">MARKET</dd>
                      </dl>
                    </div>
                    <div className="col-4">
                      <dl className="row mb-0 positionRow">
                        <dt className="col-sm-6">End Price:</dt>
                        <dd className="col-sm-6">{row?.endPrice}</dd>
                        <dt className="col-sm-6">Start Price</dt>
                        <dd className="col-sm-6">{row?.startPrice}</dd>
                        <dt className="col-sm-6">Profit/Loss</dt>
                        <dd className={(row?.side == 'BUY') ? 'col-sm-6  text-success' : 'col-sm-6 text-danger'}>{row?.profitLoss?.toFixed(4)}</dd>
                        {/* className={(row?.side == 'BUY') ? 'badge btn  bg-success' : 'badge btn  bg-danger'} */}
                        {/* <dd className="col-sm-6">
                        <p style={{color:'red'}}>{row?.closeStatus === true ? 'Closed' : 'Open'}</p>
                      </dd> */}
                      </dl>
                      <div className="d-grid gap-2 d-md-block mt-2">
                        {/* <button className="btn btn-danger" type="button">Close</button> */}
                        {/* <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* <tr className="table-row" key={i}>
            <td className="tabel-text w inter">{serialNumber}</td>
            <td className="tabel-text w inter">{row?.orderId}</td>
            <td className="tabel-text w inter">{row?.symbol}</td>
            <td className="tabel-text w inter">{row?.status}</td>
            <td className="tabel-text w inter">{row?.price}</td>
            <td className="tabel-text w inter">{row?.orderType}</td>
            <td className="tabel-text w inter">{row?.exchangeName}</td>
            <td className="tabel-text w inter">{row?.leverage}</td>
            <td className="tabel-text w inter">{row?.type}</td>
            <td className="tabel-text w inter">{row?.side}</td>
            <td className="tabel-text w inter">{row?.endPrice}</td>
            <td className="tabel-text w inter">{row?.startPrice}</td>
            <td  className={`   ${row?.profitLoss < 0 ? 'text-red' : 'text-green'}`}>{row?.profitLoss?.toFixed(4)}</td>
            <td>{dateFormat(row.createdAt, "yyyy-mm-dd, hh:mm:ss TT")}</td>
            <td> {row?.closeStatus === true ? (<><p style={{color:"red"}}>Closed</p></>) : (<><p style={{color:"green"}}>open</p></>)} </td>
            <td className="tabel-text w inter">  <button className="btn btn-primary" onClick={() => handleStatusChange(row._id, 'openview')}>View</button>  </td>
          </tr> */}
          </>

        );
      });
    } else {
      return (
        <tr>
          <td colSpan="13" className='text-center text-light m-auto '>Data Not Found</td>
        </tr>
      );
    }
  };


  return (
    <>

      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5 p-2">
              <div className="card start-date">
                <div className='main-12'>
                  <div className='row'>


                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <input type='date' className='form-control user-text' name="start_date" />
                      {/* <i class="fa-solid fa-calculator"></i> */}
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <input type='date' className='form-control user-text' name="start_date" />
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <button type='date' className='btn form-control user-text'><i className='fa fa-search'></i></button>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <select name="kycStatus" className="input01 form-control" id="status">
                        <option value="">Select Currency</option>
                        <option value="1">PENDING</option>
                        <option value="2">APPLIED</option>
                        <option value="3">APPROVED</option>
                        <option value="4">REJECTED</option>
                      </select>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="User Id Search"
                        />
                        <button>
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 p-1'>
                      {/* Filter Button */}
                      <div className="filter-btn form-control">
                        <button>
                          <i className="fa fa-filter"></i>
                          Filters
                        </button>
                      </div>
                    </div>



                  </div>


                </div>
              </div>
            </div>


            {records()}

            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default OpenPosition
