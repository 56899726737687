import React from 'react'
import Navbar from './Navbar'
import './home.css'
import { NavLink } from 'react-router-dom'
import { FaChevronRight } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import Footer from './Footer';
import part3 from '../assets/img/part3.png'
import part2 from '../assets/img/part2.png'
import part1 from '../assets/img/part1.png'
import Footeres from './Footeres'
const Home = () => {
    return (
        <>
            <div className="main">
                <Navbar />
                <main className='main' style={{ overflowX: "hidden" }}>
                    <section className='foreign_exchange'>
                        <div className="container container_p">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                                    <h1 className='heading_h1 fw-bold'>
                                        What is Future  <span className="primary_cl">Trades Goal? </span>

                                    </h1>
                                    <p className="pt-3 text-muted primary_para">
                                        It is a premier cryptocurrency exchange offering a wide range of services for crypto enthusiasts, traders, and investors.
                                    </p>
                                    <p className="text-muted primary_para">

                                        The platform provides spot trading, allowing users to buy and sell popular cryptocurrencies such as BTC, XRP, and USDT.
                                    </p>

                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <img src={part3} alt="" className='img-fluid' />
                                </div>

                            </div>
                        </div>
                    </section>
                    <section className='future-trade' data-aos="fade-left">
                        <div className="container container_p">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4 d-flex justify-content-center align-items-center">
                                    <img src={part2} alt="" className='img-fluid' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                                    <h1 className='heading_h1 fw-bold'>How it works?
                                    </h1>
                                    <p className="pt-3 text-muted primary_para">FutureBot operates with three key panels: Admin, Trader, and User.
                                        Admin Panel: The admin has exclusive control over adding and managing traders. Only the admin can authorize traders to place orders and manage the exchange operations.</p>
                                    <p className="pt-3 text-muted primary_para">Trader Panel: Traders, once authorized by the admin, can place orders on behalf of users. Each trade generates a Batch ID through the trader’s activity, streamlining transaction tracking and management.
                                        User Panel: Users are responsible for managing their own exchange keys to engage in transactions. They can: Bind new exchange keys, Unbind existing ones and Update the keys when necessary.
                                    </p>
                                </div>

                            </div>


                        </div>
                    </section>

                    <section className='w_ft' data-aos="flip-up">
                        <div className="container">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <h1 className="fw-bold heading_h1">What is future bot?</h1>
                                {/* <p className="pt-2 text-muted primary_para">Here's a brief overview of how Crypto Future trading works:</p> */}
                            </div>

                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                                    {/* <h1>We’ve built a platform to buy and sell shares.
                                    </h1> */}
                                    <div className="timeline mt-3">
                                        <div className="timeline_box mt-4">
                                            {/* <h3>Currency pairs</h3> */}
                                            <div className="pt-2 text-muted">A future bot is an automated trading program specifically designed for trading futures contracts in financial markets, including cryptocurrency markets.</div>
                                        </div>
                                        <div className="timeline_box mt-4">
                                            {/* <h3>Bid and Ask prices</h3> */}
                                            <div className="pt-2 text-muted">These bots are programmed to execute buy and sell orders based on specific rules and strategies without human intervention. In the context of cryptocurrency, a futures bot focuses on trading cryptocurrency futures contracts, which are agreements to buy or sell a digital asset at a predetermined price at a specific time in the future.</div>
                                        </div>
                                        {/* <div className="timeline_box mt-4">
                                            <h3>Going long and going short
                                            </h3>
                                            <div className="pt-2 text-muted">Traders have the option to either buy (go long) a currency pair if they believe its value will rise or sell (go short) a currency pair if they think its value will fall.
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-4">
                                    <img src={part1} alt="" className='img-fluid img_scale' />
                                </div>

                            </div>


                        </div>
                    </section>

                    <section className='w_ft' data-aos="flip-left">
                        <div className="container">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <h1><span className='primary_cl' style={{ fontSize: "20px" }}>Future-trade Feature
                                </span></h1>
                                <h1 className="pt-2 heading_h1 fw-bold">Best Features </h1>
                                <p className="pt-2 text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.

                                </p>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-1.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>How do we manage risks?</h5>
                                                <p className='text-muted mt-3'>We manage risk by offering a binding exchange service that allows users to set advanced order types, such as stop-loss and take-profit orders. These orders help manage risk by automatically closing positions when certain price levels are reached, minimizing potential losses. Additionally, through our service, users can control larger positions with a smaller amount of capital by utilizing leverage, giving them greater flexibility in managing their trades.</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            We manage risk by offering a binding exchange service that allows users to set advanced order types, such as stop-loss and take-profit orders. These orders help manage risk by automatically closing positions when certain price levels are reached, minimizing potential losses. Additionally, through our service, users can control larger positions with a smaller amount of capital by utilizing leverage, giving them greater flexibility in managing their trades.</div>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-2.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>What do you mean by binding exchange?</h5>
                                                <p className='text-muted mt-3'>Binding an exchange refers to the process of connecting a trading platform, bot, or application to a specific cryptocurrency or financial exchange. This connection enables the platform or bot to interact directly with the exchange’s services and functionalities.</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            An internal market structure break is simply when there is a minor break in structure in between the major swing highs and lows, indicating a shift in orderflow.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-3.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>Why do we need to bind exchange?</h5>
                                                <p className='text-muted mt-3'>Binding an exchange refers to connecting a trading platform, bot, or service to a cryptocurrency or financial exchange to facilitate trading. This process is crucial for several reasons like direct access to market, automated trading, to access real time data, fund transfers and many more?</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            Crypto Future trading, the common name for the foreign exchange market, entails trading currencies instead of securities (think the U.S. dollar, European euro or Japanese yen).
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-4.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>Why it is necessary to update the exchange?</h5>
                                                <p className='text-muted mt-3'>Updating the exchange is essential for several reasons to ensure smooth and efficient trading operations, especially in dynamic markets like cryptocurrencies and futures. These reasons include access to new features, security improvements, support for new listings, and more.</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            In conclusion, Crypto Future trading can be a legitimate and profitable form of investment, but it is important to be aware of the potential for scams.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-5.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>How can you level up?</h5>
                                                <p className='text-muted mt-3'>A user can level up by building a larger team, either through direct referrals or by growing their team with indirect referrals from existing members.</p>
                                            </div>
                                        </div>
                                        <div className="card_hover_div d-none">
                                            Once negotiations are completed, Foreign Exchange Contract Insurance allows the company to set the forward rate of the currency in order to stabilise proceeds in that currency.
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-4">
                                    <div className="card level-up">
                                        <div className="card-body">
                                            <div className="card_icn">
                                                <img src="https://future-trade.io/vendor/images/feature-icon-6.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="card_content mt-2">
                                                <h5 className='pt-3'>Do we need to monitor and execute trades in future bot?

                                                </h5>
                                                <p className='text-muted mt-3'>future bot is a tool that automates futures trading, allowing traders to profit from price movements in cryptocurrency or other assets without having to manually monitor and execute trades.



                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className="card_hover_div">
                                            A Crypto Future expert advisor is an automated system, which monitors the Crypto Future markets and provides signals upon identifying trading opportunities.
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section cla
                    
                    
                    ssName="faqs" data-aos="zoom-in-left">
                        <div className="container">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div><span className="primary_cl">FAQS</span></div>
                                <h1 className='fw-bold'>Frequently Asked questions</h1>
                                <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.
                                </p>
                            </div>
                            <div className="row mt-2">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                    <img src="https://future-trade.io/vendor/images/faq_img002.png" alt="" className='img-fluid' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 mt-2">
                                    <div className="tabs">
                                        <ul class="nav nav-tabs">
                                            <li class="nav-item">
                                                <a class="nav-link tab_links active" href="#onetab" data-bs-toggle="tab">Genral</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#twotab" data-bs-toggle="tab">Pre ICO & ICO</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#threetab" data-bs-toggle="tab">Token</a>
                                            </li>
                                        </ul>


                                        <div className='tab-content' id="mytab">
                                            <div className="tab tab-pane" id='onetab'>
                                                <div id="accordion" className='accordion'>
                                                    <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap1">
                                                        What is Crypto Future Trading?
                                                        <FaAngleDown />
                                                    </button>
                                                    <div className="accordion_content collapse" id='collap1'>
                                                        <p className="text-muted">Crypto Future trading, also known as foreign exchange trading or currency trading, involves the buying and selling of currencies on the foreign exchange market with the aim of making a profit. The Crypto Future market is the
                                                            largest and most liquid financial market globally, where currencies are traded 24 hours a day, five days a week.
                                                        </p>
                                                    </div>


                                                    <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap2">
                                                        How can I participate in the Crypto Future trade sale?
                                                        <FaAngleDown />
                                                    </button>
                                                    <div className="accordion_content collapse" id='collap2'>
                                                        <p className="text-muted">
                                                            The foreign exchange (Crypto Future or FX) market is a global marketplace for exchanging national currencies. Because of the worldwide reach of trade, commerce, and finance, Crypto Future markets tend to be the world's largest and most liquid asset markets. Currencies
                                                            trade against each other as exchange rate pairs.

                                                        </p>
                                                    </div>


                                                    <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collap3">
                                                        How do I benefit from the ICO Token?
                                                        <FaAngleDown />
                                                    </button>
                                                    <div className="accordion_content collapse" id='collap3'>
                                                        <p className="text-muted">

                                                            Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                        </p>
                                                    </div>







                                                </div>
                                            </div>

                                            <div className="tab-pane" id='twotab'>
                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab4">
                                                    How do I benefit from the ICO Token?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab4'>
                                                    <p className="text-muted">
                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab5">
                                                    What is Ico Trading?
                                                    <FaAngleDown />

                                                </button>
                                                <div className="accordion_content collapse" id='collaptab5'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab6">
                                                    How can I participate in the ICO Token sale?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab6'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>













                                            </div>

                                            <div className="tab-pane" id='threetab'>
                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab9">
                                                    How can I participate in the ICO Token sale?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab9'>
                                                    <p className="text-muted">
                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab10">
                                                    How do I benefit from the ICO Token?
                                                    <FaAngleDown />

                                                </button>
                                                <div className="accordion_content collapse" id='collaptab10'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab12">
                                                    What Tradingcurrencies can I use to purchase?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab12'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>

                                                <button type='button' className='btn_accordion' data-bs-toggle="collapse" data-bs-target="#collaptab11">
                                                    What is Ico Trading?
                                                    <FaAngleDown />
                                                </button>
                                                <div className="accordion_content collapse" id='collaptab11'>
                                                    <p className="text-muted">

                                                        Lorem ipsum dolor sit amet, consectetur adipisc elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. eiusmod tempor incididunt ut labore et dolore magna aliqua.

                                                    </p>
                                                </div>













                                            </div>








                                        </div>











                                    </div>
                                </div>
                            </div>


                        </div>
                    </section> */}



                    <Footeres />
                </main>
            </div>
        </>
    )
}

export default Home
