// // import React from 'react'

// // const Club = () => {
// //   return (
// //     <div>


// //     </div>
// //   )
// // }

// // export default Club

// import React, { useEffect, useState } from 'react'
// import toast from 'react-hot-toast'
// import { Pagination } from "antd";

// import { ColorRing } from 'react-loader-spinner';
// import { base_url } from '../Config/config';
// import _fetch from '../Config/api';
// import dateFormat from "dateformat";
// import { NavLink } from 'react-router-dom';
// const Club = () => {
//   const [data, setData] = useState()
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSer, setpageSer] = useState(0);

//   const handledashboard = async (page = 1, pageSize = 10) => {
//     try {
//       const skip = (page - 1) * pageSize;
//       setpageSer(skip);
//       const requestData = {
//         limit: pageSize,
//         skip: skip,



//       };

//       let response = await _fetch(`${base_url}api/user/teamMembers`, 'post', requestData);

//       if (response.success === true) {
//         setData(response);

//       } else {
//         toast.error(response.message);
//       }
//     } catch (error) {
//       console.log("Error details", error);
//     }
//   }


//   useEffect(() => {
//     handledashboard(currentPage, 10);
//   }, [currentPage]);
//   const handlePaginationChange = (pageOne) => {
//     setCurrentPage(pageOne);

//   };

//   console.log('data', data);

//   const records = () => {
//     if (data && data?.data && data?.data?.length > 0) {
//       return data?.data?.map((row, i) => {
//         const serialNumber = pageSer + i + 1;
//         return (
//           <div key={i} className="container mt-1">
//             <div className="row">
//               <div className="col-md-6">
//                 <div className='card border-primary'>
//                   <div className='card-body p-2'>
//                     <dl className="row mb-0 positionRow">
//                       <dt className="col-6 Amount">$24</dt>
//                       {<dd className="col-6 p-0">25-12-2024</dd>}
//                       {<dt className="col-6">Direct Bonus</dt>}
//                       {<dd className="col-6 p-0">Description</dd>}
//                     </dl>
//                   </div>
//                 </div>
//               </div>



//               <div className="col-md-6">
//                 <div className='card border-primary'>
//                   <div className='card-body p-2'>
//                     <dl className="row mb-0 positionRow">
//                       <dt className="col-6 Amount">$24</dt>
//                       {<dd className="col-6 p-0">25-12-2024</dd>}
//                       {<dt className="col-6">Direct Bonus</dt>}
//                       {<dd className="col-6 p-0">Description</dd>}
//                     </dl>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         );
//       });
//     } else {
//       return <div>
//         <p className='text-center text-light mt-5'>Data Not Found</p>
//       </div>;
//     }
//   };



//   return (
//     <>



//       <div className="section2">

//         <div className="table-sec">
//           <div className="transactions">
//             <div className="container mt-5">
//               <div className="card">
//                 {/* <div className='row'>
//                   <div className='main-12'>

//                     <div className=''>
//                       <input type='date' className='form-control' name="start_date" />
//                     </div>
//                     <div className=''>
//                       <input type='date' className='form-control' name="start_date" />
//                     </div>
//                     <div className=''>
//                       <button type='date' className='btn'><i className='fa fa-search'></i></button>
//                     </div>
//                   </div>



//                   <div className="main-table-2 mt-3">
//                     <select name="kycStatus" className="input01" id="status">
//                       <option value="">Select Currency</option>
//                       <option value="1">PENDING</option>
//                       <option value="2">APPLIED</option>
//                       <option value="3">APPROVED</option>
//                       <option value="4">REJECTED</option>
//                     </select>
//                     <div className="input-group">
//                       <input
//                         type="text"
//                         placeholder="User Id Search"
//                       />
//                       <button>
//                         <i className="fa fa-search"></i> 
//                       </button>
//                     </div>






//                     {/* Filter Button 
//                     <div className="filter-btn">
//                       <button>
//                         <i className="fa fa-filter"></i> {/* Filter Icon 
//                         Filters
//                       </button>
//                     </div>
//                   </div>

//                 </div> */}
//               </div>
//             </div>
//             <div className="roundeded  p-2 mt-3">
//               <h3 className='scan text-center'>Bonus</h3>
//               <div className="col mt-3">
//                 <ul class="nav nav-pills nav-fill">
//                   <li class="nav-item direct-2">
//                     <NavLink class="nav-link" to="/dashBonus" >Direct Team</NavLink>
//                   </li>
//                   <li class="nav-item team-current">
//                     <NavLink class="nav-link secend-page " aria-current="page" to="/club">Club Bonus</NavLink>
//                   </li>
//                   <li class="nav-item direct-2">
//                     <NavLink class="nav-link" to="/layalty">Loyalty Bonus</NavLink>
//                   </li>


//                 </ul>

//               </div>
//             </div>

//             {records()}


//             <div className="pagination">
//               <Pagination
//                 style={{ cursor: "pointer" }}
//                 className="mx-auto"
//                 current={currentPage || 1}
//                 total={data?.count || 0}
//                 pageSize={10}
//                 onChange={handlePaginationChange}
//                 showSizeChanger={false}
//                 showQuickJumper={false}
//               />

//             </div>
//           </div>

//         </div>
//       </div>
//     </>
//   )
// }

// export default Club

import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Pagination, Row } from "antd";

import { ColorRing } from 'react-loader-spinner';
import { base_url } from '../Config/config';
import _fetch from '../Config/api';
import dateFormat from "dateformat";
import { NavLink } from 'react-router-dom';
const Club = () => {
  const [data, setData] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSer, setpageSer] = useState(0);

  const handledashboard = async (page = 1, pageSize = 10) => {
    try {
      const skip = (page - 1) * pageSize;
      setpageSer(skip);
      const requestData = {
        limit: pageSize,
        skip: skip,
        transactionType: "clubBonus",



      };

      let response = await _fetch(`${base_url}api/user/bonusTxns`, 'post', requestData);

      if (response.success === true) {
        setData(response);

      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error details", error);
    }
  }


  useEffect(() => {
    handledashboard(currentPage, 10);
  }, [currentPage]);
  const handlePaginationChange = (pageOne) => {
    setCurrentPage(pageOne);

  };

  console.log('data', data);

  const records = () => {
    if (data && data?.data && data?.data?.length > 0) {
      return data?.data?.map((row, i) => {
        const serialNumber = pageSer + i + 1;
        return (
          <div key={i} className="container mt-1">
            {/* <div className="card border-primary"> */}
            {/* <div className="card-body p-2"> */}
            <div className="row">
              <div className="col-md-12">
                <div className='card border-primary'>
                  <div className='card-body p-2'>
                    <dl className="row mb-0 positionRow">
                      <dt className="col-7  Amount">${row.amount}</dt>
                      {<dt className="col-5">{dateFormat(row?.updatedAt, "yyyy-mm-dd, hh:mm")}</dt>}

                      {<dd className="col-7 ">{row.tokenName}</dd>}
                      {<dd className="col-5">{row.transactionType}</dd>}
                      {<dd className="col-12">{row.description}</dd>}
                      {/* {<dd className="col-4 p-2">From:{row.from}</dd>}
                      {<dd className="col-6 p-0">Side:{row.side}</dd>}
                      {<dd className="col-6 p-0">To:{row.to}</dd>} */}

                      {/* {<dt className="col-4 p-2"> date{row.updatedAt}</dt>} */}
                      {/* {<dt className="col-6">Wallet Type{row.walletType}</dt>} */}
                    </dl>
                  </div>
                </div>
              </div>




            </div >
            {/* </div> */}
            <div div className="col-md-6" >
              <dl className="row mb-0 positionRow">
              </dl>
            </div >



            {/* </div> */}
          </div >
        );
      });
    } else {
      return <div>
        <p className='text-center text-light mt-5'>Data Not Found</p>
      </div>;
    }
  };



  return (
    <>



      <div className="section2">

        <div className="table-sec">
          <div className="transactions">
            <div className="container mt-5">
              <div className="card">
                {/* <div className='row'>
                  <div className='main-12'>

                    <div className=''>
                      <input type='date' className='form-control' name="start_date" />
                    </div>
                    <div className=''>
                      <input type='date' className='form-control' name="start_date" />
                    </div>
                    <div className=''>
                      <button type='date' className='btn'><i className='fa fa-search'></i></button>
                    </div>
                  </div>



                  <div className="main-table-2 mt-3">
                    <select name="kycStatus" className="input01" id="status">
                      <option value="">Select Currency</option>
                      <option value="1">PENDING</option>
                      <option value="2">APPLIED</option>
                      <option value="3">APPROVED</option>
                      <option value="4">REJECTED</option>
                    </select>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="User Id Search"
                      />
                      <button>
                        <i className="fa fa-search"></i> 
                      </button>
                    </div>






                    {/* Filter Button 
                    <div className="filter-btn">
                      <button>
                        <i className="fa fa-filter"></i> {/* Filter Icon 
                        Filters
                      </button>
                    </div>
                  </div>

                </div> */}
              </div>
            </div>
            <div className="rounded  p-2 mt-3">
              <h3 className='scan text-center'>Bonus</h3>
              <div className="col mt-4">
                {/* <ul class="nav nav-pills nav-fill p-0">
                  <li class="nav-item team-current">
                    <NavLink class="nav-link secend-page " aria-current="page" to="/direct">Direct Team</NavLink>
                  </li>
                  <li class="nav-item direct-2">
                    <NavLink class="nav-link" to="/club">Club Bonus</NavLink>
                  </li>
                  <li class="nav-item direct-2">
                    <NavLink class="nav-link" to="/layalty">Loyalty Bonus</NavLink>
                  </li>


                </ul> */}
                <ul class="nav nav-pills nav-fill">
                   <li class="nav-item direct-2">
                     <NavLink class="nav-link" to="/dashBonus" >Direct Team</NavLink>
                   </li>
                   <li class="nav-item team-current">
                     <NavLink class="nav-link secend-page " aria-current="page" to="/club">Club Bonus</NavLink>
                   </li>
                   <li class="nav-item direct-2">
                     <NavLink class="nav-link" to="/layalty">Loyalty Bonus</NavLink>
                   </li>


                 </ul>

              </div>
            </div>

            {records()}


            <div className="pagination">
              <Pagination
                style={{ cursor: "pointer" }}
                className="mx-auto"
                current={currentPage || 1}
                total={data?.count || 0}
                pageSize={10}
                onChange={handlePaginationChange}
                showSizeChanger={false}
                showQuickJumper={false}
              />

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Club
